.g__fcontact {
  background-color: rgb(38, 128, 63);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem 0rem;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}

.g__fcontact:hover {
  transform: scale(102%);
  transition: 0.3s all ease-in-out;
}

.g__fcontact-t {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  color: white;

  padding: 0rem 2rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
}

.g__button {
  background-clip: padding-box;
  background-color: #ffb703;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  border-radius: 10px;
  padding: 1rem;
}

@media (min-width: 1199.98px) {
  .g__fcontact {
    border-radius: 20px;

    margin: 2rem;
    min-height: 18rem;
    max-height: 20rem;
    min-width: 30rem;
    max-width: 80rem;
    position: relative;
  }
  .g__fcontact-t {
    font-size: 1.5rem;
  }
}
