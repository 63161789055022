.g__form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
  min-height: 60vh;
  max-height: 80vh;

  z-index: 1;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.85);
}
form {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  max-width: 90vw;
  border-radius: 10px;
}

form h2 {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem;
  text-align: left;
  color: #2b9348;
  /* text-shadow: 3px 3px 8px #000000; */
}

form input,
form textarea {
  margin: 0.75rem 0;
  padding: 1.15rem;
  outline: none;
  background: transparent;
  color: #003855;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
}

form button {
  background: rgba(43, 147, 72, 0.9);
  color: white;
  font-size: 1.15rem;
  padding: 1rem 0rem;
  width: 8rem;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  border: none;
}

@media (min-width: 767.98px) {
  form {
    padding: 2rem 4rem;
  }
}
@media (min-width: 1199.98px) {
  form {
    padding: 1rem 5rem;
  }
  form h2 {
    font-size: 2rem;
  }
}
