.g__ferti-header {
  background-image: url(../../assets/ferti.jpg);
  background-size: cover;
  background-position-y: center;
  background-position-x: center;

  max-width: 100vw;
  height: 60vh;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.g__ferti-title {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  text-shadow: 3px 3px 8px #000000;

  text-align: center;
  padding: 0rem 2rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.g__ferti-para1 {
  padding: 2rem;

  font-size: 1.35rem;
  font-weight: 350;
}

.g__ferti-con {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1199.98px) {
  .g__ferti-con {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .g__ferti-para1 {
    padding: 4rem;
    font-size: 1.65rem;
  }
}
