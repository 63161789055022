.g__navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  top: 0;

  z-index: 2;

  max-width: 100vw;
  width: 100%;
  max-height: 30rem;
  height: auto;

  padding: 0rem 0rem;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  /* transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  background-image: linear-gradient(to right, #13aa5f, #017260); */
}

.nav-item {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
  transition: all 1s ease-in-out;
}

.dropdown {
  position: absolute;
  top: 80px;
  left: 40vw;
  width: 15rem;

  /* transform: translateX(-45%); */
  background-color: white;

  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  padding: 1rem;
  overflow: hidden;

  z-index: 2;
  transition: all 1s ease-in-out;
  animation: growDown 300ms ease-in-out forwards;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5%;
  padding: 0.5rem;
  z-index: 2;
}

.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.g__navbar-contact {
  display: flex;
  flex-wrap: wrap;
  max-height: 3rem;
  width: 100%;
  justify-content: center;
  background-color: white;
  padding: 0.2rem 2rem;
}
.navbarPhone {
  height: 0.85rem;
  color: #2b9348;
  text-decoration: none;
  width: 1rem;
  overflow: visible;
}
.navbarClock {
  height: 0.85rem;
  color: #2b9348;
  text-decoration: none;
  width: 1rem;
  overflow: visible;
}
.📞 {
  display: flex;
  flex-direction: row;
  text-align: center;

  font-size: 1rem;
  font-weight: 600;
  color: #2b9348;
  overflow: visible;
}
.🕘 {
  margin-left: 1.1rem;
  color: #2b9348;

  font-size: 1rem;
  font-weight: 500;
  overflow: visible;
}

.g__navbar-links_logo {
  background-image: url(../../assets/logo.jpg);
  background-size: contain;
  background-repeat: no-repeat;

  width: 6.5rem;
  height: 5.25rem;

  overflow: visible;
  transition: all 1s ease-in-out;
}

.g__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  padding: 0.5rem 2rem;
}

.g__navbar-links_container {
  display: flex;
  flex-direction: row;
  padding-left: 30vw;
}

.g__navbar-links_container a
/* .g__navbar-menu_container a */ {
  color: #2b9348;
  padding: 0rem 0.75rem;
  font-size: 1.2rem;
  transition: filter 300ms;
  min-width: auto;
}

.g__navbar-links_container a:hover,
.g__navbar-menu_container a:hover {
  filter: brightness(1.2);
  /* background-color: rgba(43, 147, 72, 0.5);
  padding: 0.5rem;
  border-radius: 5%; */
}

.g__navbar-menu {
  margin-top: 0;
  margin-left: auto;
  margin-right: -1em;

  display: none;
  position: relative;
}

.g__navbar-menu_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  z-index: 1;

  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;

  background: rgba(245, 245, 245, 0.52);
  padding: 2rem 4rem;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-list {
  display: flex;
  align-items: center;

  padding-top: 4rem;
}

.nav-list li {
  line-height: 8rem;
  padding: 0.2rem;
  position: relative;
}

.sub-menu li {
  line-height: 2rem;
}

.nav-list a {
  display: flex;
  position: relative;
  color: #eee;
  padding: 0 2rem;
  font-size: 1.75rem;
}

.nav-list a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.sub-menu {
  width: 100vw;
  position: relative;
  height: 0;

  display: flex;
  flex-direction: column;

  z-index: 0;
  /* transition: all 650ms ease; */
  -moz-transition: height 0.2s ease 0.2s;
  -webkit-transition: height 0.2s ease 0.2s;
  -o-transition: height 0.2s ease 0.2s;
  transition: height 0.2s ease 0.2s, border-left 0s ease 0s;

  opacity: 0;
  top: 0;
  left: 0vw;
  border-left: 3px solid transparent;
  border-left-color: #13aa5f;
  margin-left: 2rem;
}

.sub-menu li {
  /* transition: all 650ms ease; */
  -moz-transition: opacity 0.25s ease;
  -webkit-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;

  opacity: 0;
}

.sub-menu.active {
  -moz-transition: height 0.25s ease;
  -webkit-transition: height 0.25s ease;
  -o-transition: height 0.25s ease;
  transition: height 0.25s ease;

  height: 18rem;
  opacity: 1;
}

.sub-menu.active li {
  opacity: 1;

  -moz-transition: opacity 0.2s ease 0.2s;
  -webkit-transition: opacity 0.2s ease 0.2s;
  -o-transition: opacity 0.2s ease 0.2s;
  transition: opacity 0.2s ease 0.2s;
}

.sub-menu::before {
  content: "";
  position: absolute;
  top: -2.5rem;
  left: 3rem;
  border: 1.2rem solid transparent;
  border-bottom-color: #13aa5f;
  display: none;
}
.sub-menu .sub-menu::before {
  top: 0.9rem;
  left: -2.5rem;
  border: 1.2rem solid transparent;
}
/* 
.sub-menu li:hover > .sub-menu {
  left: 100%;
  opacity: 1;
  visibility: visible;
}
.nav-list li:hover > .sub-menu {
  top: 8rem;
  opacity: 1;
  visibility: visible;
} */

@media (max-width: 575.98px) {
  .nav-list a {
    font-size: 1.4rem;
  }
  .sub-menu li {
    line-height: 1rem;
  }
  .nav-list li {
    line-height: 2rem;
    padding: 0;
  }
}

@media (max-width: 875.98px) {
  .g__navbar-links_container {
    display: none;
  }
  .g__navbar-menu {
    display: flex;
  }
  .g__navbar-links_container a,
  .g__navbar-menu_container a {
    color: #003855;
  }
  .g__navbar-links_logo {
    width: 5rem;
    height: 3rem;
  }

  .nav-list {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(245, 245, 245, 0.8);
    padding-top: 8rem;
    padding-left: 0rem;
  }

  .nav-list > li {
    line-height: 3rem;
    margin-left: 16vw;
  }

  /* 
  .nav-list li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  } */

  li.move-right {
    margin: 0 auto 0 0;
  }
}

@media (max-width: 991.98px) {
  .g__navbar-links_container {
    padding-left: 20%;
  }
}

@media (max-width: 1199.98px) {
}
