.g__propos-title {
  margin-top: 8rem;
  text-align: center;
  font-size: 2.75rem;
  font-weight: 800;
  color: #2b9348;
}

.g__propos-div1 {
  height: 40rem;
  width: 20.4rem;
  margin: 2rem;
  border: 3px solid black;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.g__propos-con {
  margin: 1rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.g__propos-div1-1 {
  background-image: url(../assets/realisation1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;

  border-bottom: 1px solid black;

  width: 20rem;
  height: 20rem;
}

.g__propos-div1-2 {
  background-image: url(../assets/realisation1.5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -6rem;

  width: 20rem;
  height: 20rem;
}

.g__propos-div2-1 {
  background-image: url(../assets/realisation2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;

  border-bottom: 1px solid black;

  width: 20rem;
  height: 20rem;
}

.g__propos-div2-2 {
  background-image: url(../assets/realisation2.5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -6rem;

  width: 20rem;
  height: 20rem;
}
