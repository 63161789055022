.g__contact-header {
  background-color: #13aa5f;

  max-width: 100vw;

  margin-top: 3rem;
  padding: 0rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first {
  /* background-image: url(../assets/contact-photo.png);
  background-size: cover; */

  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.first1 {
  background-image: url(../assets/terro2.jpg);
  background-size: cover;
  position: absolute;
  top: 85px;
  left: 0;

  height: 46.5vh;
  width: 100vw;
  z-index: 0;
}

.first2 {
  background-image: url(../assets/terro.jpg);
  background-size: cover;
  position: absolute;
  top: 54vh;

  height: 46.5vh;
  width: 100vw;
  z-index: 0;
  overflow: hidden;
}

@media (min-width: 767.98px) {
  .first1 {
    background-image: url(../assets/terro2.jpg);
    background-size: cover;
    position: absolute;
    top: 85px;
    left: 0;

    height: 91.2vh;
    width: 50vw;
    z-index: 0;
  }
  .first2 {
    background-image: url(../assets/terro.jpg);
    background-size: cover;
    position: absolute;
    top: 85px;
    left: 50vw;

    height: 91.2vh;
    width: 50vw;
    z-index: 0;
  }
}
