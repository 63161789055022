.g__terreau-header {
  background-image: url(../../assets/terreautage3.png);
  background-size: cover;
  background-position-y: 2rem;
  background-position-x: center;

  max-width: 100vw;
  height: 60vh;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.g__terreau-title {
  font-size: 3rem;
  font-weight: 600;
  color: white;
  text-shadow: 3px 3px 8px #000000;

  text-align: center;
  padding: 0rem 2rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.g__terreau-p1 {
  color: #2b9348;
  font-weight: 800;
  font-size: 1.55rem;
}

.g__terreau-para1 {
  padding: 2rem;

  font-size: 1.35rem;
  font-weight: 350;
}

.g__terreau-p {
  padding-top: 2rem;
}

.g__terreau-h6 {
  font-size: 1.6rem;
  color: #2b9348;

  padding-top: 1.75rem;
}

.g__terreau-span {
  color: #003855;
  font-weight: 600;
}
.g__terreau-enumcon {
  padding-top: 0.75rem;
}
.g__terreau-enum {
  padding-bottom: 1rem;
}

@media (min-width: 991.98px) {
}
@media (min-width: 1199.98px) {
  .g__terreau-con {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .g__terreau-para1 {
    padding: 4rem;
    font-size: 1.65rem;
  }
}
