.g__home-header {
  /* background-image: url(../assets/gazon6.jpg);
  background-size: cover;
  background-position-y: center; */

  max-width: 100vw;
  height: 75vh;

  margin-top: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust to your preference */
  overflow: hidden;
}

/* If you want 9:16 for portrait mode, you can use padding-bottom: 177.77% instead */

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensure video fills the width */
  height: 100%; /* Ensure video fills the height of its container */
  object-fit: cover; /* Cover the container without losing aspect ratio */
  z-index: 1;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers text */
  align-items: center; /* Horizontally centers text */
  color: #fff; /* Text color, adjust as needed */
  text-align: center;
  z-index: 1; /* Ensures text appears above video */
}
h5 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  font-weight: 500;
}
.reli__form {
  display: none;
}
.reli__form.active {
  display: flex;
  background-color: white;
  width: 100vw;
  height: 100vh;
}

.g__home-headerspan {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.5rem;
}

.g__home-imgTitle {
  font-size: 2rem;
  font-weight: 900;
  color: white;
  text-shadow: 2px 1px 4px #000000;

  text-align: center;
  padding: 0rem 2rem;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.g__home-imgTitle2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  text-shadow: 2px 1px 4px #000000;

  text-align: center;
  padding: 0rem 2rem;
  padding-top: 0.25rem;
  padding-bottom: 2rem;
}

.buttonDevis {
  background-clip: padding-box;
  background-color: #ffb703;
  border: 1px solid #ffb703;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #003855;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  border-radius: 10px;
  padding: 1rem;
}

.buttonDevis:hover,
.buttonDevis:focus {
  /* background-color: #ffa500; */
  background-color: rgba(255, 183, 3, 1);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  transform: translateY(-3px);
}

.buttonDevis:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.g__home-div {
  padding: 1rem;

  display: flex;
  flex-direction: column;
}

.g__home-div1 {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.g__home-title1 {
  font-size: 2.25rem;
  text-align: left;
  padding: 1rem;
  color: #2b9348;
}

.g__home-line {
  width: 85vw;
  height: 2px;
  align-self: center;
  background-color: #000000;
  border-radius: 20px;
}

.g__home-para1 {
  padding: 1rem;
  font-size: 1.25rem;
}

.g__home-div2 {
  padding: 1rem;
  margin: 1rem;

  background-color: rgba(250, 250, 250, 0.35);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;

  z-index: 1;
  overflow: hidden;
}
.g__home-div2grass {
  height: 22rem;
  width: 22rem;
  color: #2b9348;

  position: absolute;
  margin: 6rem -1.5rem;
  opacity: 0.8;
  z-index: 0;
}

.g__home-div2:hover {
  transform: scale(102%);
  transition: 0.3s all ease-in-out;
}

.g__home-title2 {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  color: #2b9348;

  /* text-shadow: 2px 2px 6px #000000; */
}

.g__home-para2 {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 1.25rem;
}

.g__home-para2 div {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  color: black;
  font-weight: 400;
}

.g__home-div3 {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.g__home-title3 {
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  color: #2b9348;

  padding-top: 2rem;
}

.g__home-services {
  padding-top: 1rem;
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.smlcon {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  transition: 0.3s all ease-in-out;
  max-width: 20rem;
  max-height: 15rem;
}

.smlcon:hover {
  transform: scale(105%);
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.service {
  height: 13rem;
  width: 20rem;

  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;

  border-radius: 10px 10px 0 0;

  font-size: 1.5rem;
}

.g__home-servicecon1 {
  background-image: url(../assets/nettoyage.png);
  background-size: cover;
  background-position-y: -2rem;
}

.g__home-servicecon2 {
  background-image: url(../assets/ferti.jpg);
}

.g__home-servicecon3 {
  background-image: url(../assets/terreautage.png);
}

.g__home-servicecon5 {
  background-image: url(../assets/ensemencement.png);
}

.g__home-servicecon6 {
  background-image: url(../assets/epandage.png);
}

.g__home-servicecon7 {
  background-image: url(../assets/aeration.png);
}
.g__home-servicecon8 {
  background-image: url(../assets/pailli.jpg);
  background-position-y: bottom;
}
.g__home-servicecon9 {
  background-image: url(../assets/autre.png);
  background-position-y: bottom;
}
.g__home-servicecon10 {
  background-image: url(../assets/mini.jpg);
  background-position-y: center;
}
@media (max-width: 575.98px) {
}

@media (min-width: 767.98px) {
  .g__home-div {
    display: flex;
    flex-direction: row;
    padding: 3rem;
    align-items: center;
  }
  .g__home-div2 {
    max-width: 18rem;
    font-size: 1.75rem;
    margin-left: 1rem;
  }
  .g__home-line {
    width: 45vw;
  }
  .g__home-para1 {
    font-size: 1.5rem;
  }
  .g__home-para2 {
    font-size: 1.25rem;
  }
}

@media (min-width: 991.98px) {
  .g__home-div {
    display: flex;
    flex-direction: row;
    padding: 3rem;
  }
  .g__home-div2 {
    max-width: 20rem;
    font-size: 1.75rem;
  }
  .g__home-para2 {
    font-size: 1.5rem;
  }
  .g__home-title1 {
    font-size: 2.5rem;
  }
  .g__home-line {
    margin: 1rem;
    align-self: flex-start;
  }
}

@media (min-width: 1199.98px) {
  .g__home-div {
    display: flex;
    flex-direction: row;
    padding: 4rem;
  }
  .g__home-div2 {
    max-width: 24rem;
    font-size: rem;
  }
  .g__home-title1 {
    font-size: 3rem;
    text-align: start;
  }
  .g__home-title2 {
    font-size: 3rem;
  }
  .g__home-para1 {
    font-size: 1.85rem;
    padding-right: 10rem;
  }
  .g__home-para2 {
    font-size: 1.65rem;
  }
  .g__home-hr {
    max-width: 50vw;
  }
  .g__home-line {
    margin: 1.5rem;
  }
}
