.g__forfait-title {
  margin-top: 8rem;
  text-align: center;
  font-size: 3rem;
  font-weight: 800;
  color: #2b9348;
}

.g__forfait-con {
  margin: 1rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.g__forfait1 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;

  width: 20rem;
  height: 30rem;

  margin: 1.5rem;

  border-radius: 5%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.g__forfait-item-title {
  color: #2b9348;
  padding: 1rem;
  font-size: 1.75rem;
}

.g__forfait-item-p {
  font-size: 1.05rem;
  font-weight: 700;
  padding: 0.75rem;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
}

.g__forfait-item {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0.5rem;
}

.g__forfait-button-con {
  padding: 1.5rem;
  margin-top: auto;
}

.g__forfait-button {
  background-clip: padding-box;
  background-color: #2b9348;
  border: none;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.15rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  padding: 1rem;
}
